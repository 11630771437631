<template>
  <div class="download-content">
    <!-- 蒙层-->
    <div class="mask" id="mask" v-if="isInWechat == true && flag == true">
      <img class="lujing" src="@/assets/imgs/download/lujing.png" mode="" />
      <div class="yindao">
        <img
          class="liulanqi"
          src="@/assets/imgs/download/liulanqi.png"
          alt=""
        />
        <span class="desc">点击右上角选择浏览器打开</span>
      </div>
    </div>

    <!-- 内容 -->
    <div class="top-wapper">
      <img class="top-left" src="@/assets/imgs/download/top-left.png" mode="" />
      <img
        class="bottom-right"
        src="@/assets/imgs/download/bottom-right.png"
        mode=""
      />

      <img class="logo" src="@/assets/imgs/download/logo.png" alt="" />

      <!-- <span class="title">考教师，库课教师助你一站成师</span>
      <p class="title-desc">精研好课随心学 师生互动任性学</p> -->

      <div class="download-wapper">
        <div v-if="isiOS" class="item" @click="openUrl('ios')">
          <img
            class="download"
            src="@/assets/imgs/download/ios.png"
            mode=""
          /><span>iPhone下载</span>
        </div>

        <div v-if="isAndroid" class="item" @click="openUrl('android')">
          <img
            class="download"
            src="@/assets/imgs/download/android.png"
            mode=""
          /><span>Android下载</span>
        </div>
      </div>

      <img
        class="top-content"
        src="@/assets/imgs/download/top-content.png"
        mode=""
      />
    </div>
    <div class="otherBg">
      <p class="download-title">多种好课随心选</p>
      <p>库课教师名师直播，高清流畅画质</p>
      <img
        class="top-content"
        src="@/assets/imgs/download/more-class.png"
        mode=""
      />
    </div>
    <!-- <div>
      <a
        data-miniprogram-appid="wx02c41d03441268e6"
        data-miniprogram-path="/pages/prod/prod?prodid=1667"
        data-miniprogram-nickname="库课图书"
        data-miniprogram-type="text"
        href=" "
        >广东高等数学精讲课试听11111</a
      >
    </div> -->
    <!-- 高效直播服务 -->
    <p class="download-title">高效直播服务</p>
    <span class="desc"> 一键直达直播间，好课尽收眼底</span>
    <span class="desc">打开APP即可随时随地免费降临名师干货直播间</span>
    <span class="desc">高清直播 师生零距离 在线互动 </span>
    <span class="desc">保姆式督学服务一对一在线指导</span>

    <div>
      <ul class="live-wapper">
        <li class="live-item">
          <img src="@/assets/imgs/download/live.png" alt="" />
          <span class="live-item-title">高效直播服务</span>
          <span class="live-item-desc">不定时狂撒备考福利</span>
        </li>
        <li class="live-item">
          <img src="@/assets/imgs/download/interface.png" alt="" />
          <span class="live-item-title">高效互动体验</span>
          <span class="live-item-desc">仿真课堂实时零距离互动</span>
        </li>
        <li class="live-item">
          <img src="@/assets/imgs/download/tv.png" alt="" />
          <span class="live-item-title">双师课堂维护</span>
          <span class="live-item-desc">听课答疑两不误</span>
        </li>
        <li class="live-item">
          <img src="@/assets/imgs/download/duxue.png" alt="" />
          <span class="live-item-title">保姆式督学</span>
          <span class="live-item-desc">随时随地一对一在线指导</span>
        </li>
      </ul>
    </div>

    <!-- 互动连麦 -->
    <div class="otherBg">
      <p class="download-title">互动连麦</p>
      <span class="desc">模拟真实上课场景，高效互动体验</span>
      <span class="desc">摒弃传统老师教，学生学的单向输出</span>
      <span class="desc"
        >借助先进的互联网技术实现视频、音频方式的互动连麦，</span
      >
      <span class="desc">打造开放、共进的线上教学环境，</span>
      <span class="desc">让上课不再受地域、天气、环境的限制，</span>
      <span class="desc"
        >随时对地听课学习，让学习更简单、真实、有趣、高效。</span
      >
      <img class="lianmai" src="@/assets/imgs/download/lianmai.png" mode="" />
    </div>

    <!-- 我的学习 -->
    <p class="download-title">我的学习</p>
    <span class="desc">实时更新课程学习进度 </span>
    <span class="desc"> 更有学习日历上课记录等功能，找课、听课方便快捷 </span>
    <span class="desc">电脑端、手机端、iPad端同步更新学习进度</span>
    <img class="lianmai" src="@/assets/imgs/download/mystudy.png" mode="" />
    <!-- 多样开始类型 -->

    <div class="otherBg">
      <p class="download-title">多样考试类型</p>
      <span class="desc">教师资格证、教师招聘、特岗教师一站式成师 </span>
      <span class="desc">全国各省份根据当地考情精研课程内容</span>
      <span class="desc">0基础小白、二次备考的学霸、繁忙的宝妈备考</span>
      <span class="desc">都有适合您的阶段学习课程</span>
      <img class="lianmai" src="@/assets/imgs/download/more-exam.png" mode="" />
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      isiOS: false,
      isAndroid: false,
      isInWechat: false,
      flag: false,
      androidDownload:
        "https://oss.kuke99.com/package/andriod/20220111/kuketeacher_release.apk",
    };
  },
  created() {
    let u = navigator.userAgent;
    // 是否是Android终端
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // Android终端
    // 是否是ios终端
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    // 是否在微信里面
    if (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
      this.isInWechat = true;
    }

    this.initUrl();
  },
  mounted() {},
  methods: {
    initUrl() {
      axios
        .post(
          `${process.env.VUE_APP_DOWNLOAD}/kuke_app/kuke_teacher_android_down`
        )
        .then((res) => {
          if (res.data.code != "0") return;
          this.androidDownload = res.data.data.url;
        });
    },
    openUrl(value) {
      this.flag = true;

      if (this.isInWechat && this.flag) {
        document.getElementById("mask").addEventListener(
          "touchstart",
          (e) => {
            e.preventDefault();
          },
          false
        );
      }

      if (value == "ios") {
        //跳转ios
        window.location.href = "https://apps.apple.com/cn/app/id1586106887";
      } else if (value == "android") {
        //跳转安卓
        window.location.href = this.androidDownload;
      }
    },
  },
};
</script>

<style scoped>
.download-content {
  font-size: 24px;
  width: 750px;
}
.top-wapper {
  padding-top: 66px;
  box-sizing: border-box;
  position: relative;
  width: 750px;
  height: 1125px;
  background: linear-gradient(100deg, #fb5e52 0%, #f9473f 100%, #f9473f 100%);
}

.top-left {
  width: 238px;
  height: 152px;
  position: absolute;
  left: 0;
  top: 0;
}

.bottom-right {
  width: 177px;
  height: 102px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.logo {
  width: 124px;
  margin-bottom: 36px;
}

.title {
  display: inline-block;
  font-size: 46px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.title-desc {
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 12px 0 40px;
}
.download-wapper {
  padding: 0 46px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 2px solid #ffffff;
  padding: 21px 37px;
}
.item span {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.item img {
  width: 40px;
  margin-right: 20px;
}

.top-content {
  width: 750px;
  height: 510px;
  margin-top: 80px;
}
.download-title {
  font-size: 46px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff3f29;
  line-height: 65px;
}

.desc {
  display: block;
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 44px;
}
.lianmai {
  width: 694px;
  height: 570px;
}

.live-wapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 60px;
}
.live-item {
  margin-top: 40px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.live-item img {
  width: 130px;
  height: 130px;
}

.live-item-title {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin: 20px 0 12px;
}

.live-item-desc {
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.otherBg {
  background: #fefbfa;
  padding-top: 70px;
}

.mask {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.lujing {
  width: 120px;
  height: 136px;
  position: absolute;
  top: 20;
  right: 47px;
}

.yindao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 170px;
}

.yindao .liulanqi {
  width: 48px;
  height: 48px;
  margin-right: 20px;
}

.yindao .desc {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 56px;
  letter-spacing: 1px;
}
</style>
